@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=Comforter&display=swap');

@font-face {
  font-family: 'Outward';
  src: url('./webfonts/outward/outward-block-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Suisse';
  src: url('./webfonts/Suisse/SuisseIntl-Light-WebS.woff') format('woff');
}



:root {
  --anim: rotate(90deg)
}

#app {
  width: 100%
}

text {
  color: blueviolet;
}

#start-text {
  font-size: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}


h2 {
  font-size: 28px;
  color: rgb(103, 177, 47);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 50%;
}

@media only screen and (max-width: 1300px) {
  #reset {
    width: 250px;
    padding: 16px 16px;
  }


  #game2btn {
    width: 250px;
    padding: 16px 16px;
  }
}

@media only screen and (max-width: 1100px) {
  #reset {
    width: 200px;
    padding: 14px 14px;
    font-size: 14px;
  }


  #game2btn {
    width: 200px;
    padding: 14px 14px;
    font-size: 14px;
  }
}


@media only screen and (max-width: 950px) {

  #reset {
    width: 135px;
    padding: 10px 10px;
    font-size: 10px;
  }

  #game2btn {
    width: 135px;
    padding: 10px 10px;
    font-size: 10px;
  }

  a {
    width: 100px;
    margin-right: 10px;
  }

}

@media only screen and (max-width: 700px) {

  #reset {
    width: 90px;
    padding: 10px 10px;
    font-size: 10px;
  }

  #game2btn {
    width: 90px;
    padding: 10px 10px;
    font-size: 10px;
  }
  #form-div {
    width: 33%;
  }


  a {
    width: 75px;
  }

}

@media only screen and (max-width: 500px) {
  #reset {
    width: 70px;
    padding: 6px 6px;
    font-size: 8px;
  }

  #game2btn {
    width: 70px;
    padding: 6px 6px;
    font-size: 8px;
  }


  a {
    width: 50px;
    font-size: 10px;
  }

}

#wrapper {
  /* padding: 20px 50px; */
  position: relative;
  display: grid;
  margin: 0px 50px;
  grid-template-columns: 1fr 1fr;
  min-height: 70vh;
}
#dropdown {
  width: 200px;
}

#wrapper-game-done {
  position: relative;
  min-height: 60vh;
}

#output {
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  animation: mymove 2s ease-in;
}

#reset {
    transform: skewY(5deg);
    transform: skewX(-10deg);
}

#header {

/* Frame 8 */

  /* Auto layout */  /* Klar til å få det på? */

  font-family: 'Outward';
  font-style: normal;
  font-weight: 400;

  color: rgba(0, 0, 0, 0.7);
}



#reset-btn:hover #reset-arrow {
  fill-opacity: 0.8;
}



@keyframes mymove {
  0% {
    font-size: 2px;
    transform: rotate(360deg);
  }

  50% {
    font-size: 18px;
  }

  75% {
    font-size: 24px;
  }

  100% {
    font-size: 28px
  }
}




body {
  margin: 0;
  font-size: 100%;
  background-color: #ADFF00;
  font-family: 'Suisse';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Suisse';
}
